<template>
  <div id="stockAgeChart" style="height: 100%; color: #fff"></div>
</template>

<script>
import { stockAgeStats } from "@/api/statistic";
import { Pie } from "@antv/g2plot";

export default {
  data() {
    return {
      dataChart: null,
      dataItems: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    queryData() {
      stockAgeStats({ warehouse: this.currentWarehouse }).then((data) => {
        this.dataItems = data;
        this.dataChart.changeData(this.dataItems);
      });
    },
  },
  mounted() {
    this.dataChart = new Pie("stockAgeChart", {
      data: [],
      autoFit: true,
      meta: {
        time: { alias: this.$t("库龄"), range: [0, 1] },
        quantity: { alias: this.$t("数量") },
      },
      appendPadding: 10,
      angleField: "quantity",
      colorField: "time",
      theme: {
        components: {
          legend: {
            common: {
              itemName: {
                style: {
                  fill: "#fff",
                  fontSize: 14,
                  lineHeight: 12,
                  fontWeight: "normal",
                  textAlign: "start",
                  textBaseline: "middle",
                },
              },
            },
          },
        },
        labels: {
          style: {
            fill: "#fff",
            fontSize: 14,
          },
        },
      },
    });
    this.dataChart.render();

    this.queryData();
  },
};
</script>
